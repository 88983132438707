const imagesIndex = {

    kitchenImages: [
        {
            imagePath: require('./Kitchens/Slide1.JPG'),
            imageName:  'Ranch Open Concept'
        },
        {
            imagePath: require('./Kitchens/Slide2.JPG'),
            imageName:  'Open Concept Kitchen Remodel'
        },
        {
            imagePath: require('./Kitchens/Slide3.JPG'),
            imageName:  'Cape Cod Custom Kitchen'
        },
        {
            imagePath: require('./Kitchens/Slide6.JPG'),
            imageName:  'Condo Kitchen Upgrade'
        },
        {
            imagePath: require('./Kitchens/Slide10.JPG'),
            imageName:  'Cape Cod Custom Island'
        },
        {
            imagePath: require('./Kitchens/Slide12.JPG'),
            imageName:  'Condo Kitchen Refresh'
        },
        {
            imagePath: require('./Kitchens/Slide14.JPG'),
            imageName:  'Condo Kitchen Full View'
        },
        {
            imagePath: require('./Kitchens/Slide15.JPG'),
            imageName:  'Condo Full Remodel Kitchen'
        },
        {
            imagePath: require('./Kitchens/Slide16.JPG'),
            imageName:  'Condo Laundry Room Divider'
        },
        {
            imagePath: require('./Kitchens/Slide38.JPG'),
            imageName:  'Condo Kitchen Remodel'
        }
    ],

    bathroomImages: [
        {
            imagePath: require('./Bathrooms/Slide5.JPG'),
            imageName:  'Rental Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide13.JPG'),
            imageName:  'Attic Spa Conversion'
        },
        {
            imagePath: require('./Bathrooms/Slide18.JPG'),
            imageName:  'Shower Gut and Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide19.JPG'),
            imageName:  'Double Sink and Floor Replacement'
        },
        {
            imagePath: require('./Bathrooms/Slide20.JPG'),
            imageName:  'Simple Refresh'
        },
        {
            imagePath: require('./Bathrooms/Slide22.JPG'),
            imageName:  'Master Bath Custom Vanity and Tile'
        },
        {
            imagePath: require('./Bathrooms/Slide24.JPG'),
            imageName:  'Shower Stall Refresh'
        },
        {
            imagePath: require('./Bathrooms/Slide25.JPG'),
            imageName:  'Master Bath Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide27.JPG'),
            imageName:  'Floor and Wall Tile Installation'
        },
        {
            imagePath: require('./Bathrooms/Slide28.JPG'),
            imageName:  'Master Suite Addition'
        },
        {
            imagePath: require('./Bathrooms/Slide36.JPG'),
            imageName:  'Simple Update'
        },
        {
            imagePath: require('./Bathrooms/Slide38.JPG'),
            imageName:  'Single to Dual Sink Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide39.JPG'),
            imageName:  'Small Bathroom Update'
        },
        {
            imagePath: require('./Bathrooms/Slide40.JPG'),
            imageName:  'Tub Surround Tile Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide41.JPG'),
            imageName:  'Tub to Walk-In Shower'
        },
        {
            imagePath: require('./Bathrooms/Slide42.JPG'),
            imageName:  'Master Bath Shower'
        },
        {
            imagePath: require('./Bathrooms/Slide46.JPG'),
            imageName:  'Master Bath Full View'
        },
        {
            imagePath: require('./Bathrooms/Slide47.JPG'),
            imageName:  'Vanity Wall Update'
        },
        {
            imagePath: require('./Bathrooms/Slide48.JPG'),
            imageName:  'Complete Bathroom Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide50.JPG'),
            imageName:  'Tub Tile Upgrade'
        },
        {
            imagePath: require('./Bathrooms/Slide51.JPG'),
            imageName:  'Modern Master Bath'
        },
        {
            imagePath: require('./Bathrooms/Slide54.JPG'),
            imageName:  'Wall Mounted Vanity + Kohler Medicine Cabinet'
        },
        {
            imagePath: require('./Bathrooms/Slide55.JPG'),
            imageName:  'Guest Bath Refresh'
        },
        {
            imagePath: require('./Bathrooms/Slide56.JPG'),
            imageName:  'Attic Bath Addition + Storage'
        }
    ],

    transformationImages: [
        {
            imagePath: require('./Transformations/Slide4.JPG'),
            imageName:  'Dining Room Custom Accent Wall'
        },
        {
            imagePath: require('./Transformations/Slide7.JPG'),
            imageName:  'Main Entrance Upgrade'
        },
        {
            imagePath: require('./Transformations/Slide8.JPG'),
            imageName:  'Interactive Teacher Office'
        },
        {
            imagePath: require('./Transformations/Slide9.JPG'),
            imageName:  'Fireplace Accent Tile Surround and Hearth'
        },
        {
            imagePath: require('./Transformations/Slide11.JPG'),
            imageName:  'Custom Hearth and Entertainment Mount'
        },
        {
            imagePath: require('./Transformations/Slide17.JPG'),
            imageName:  'Custom Entertainment Cabinet'
        },
        {
            imagePath: require('./Transformations/Slide21.JPG'),
            imageName:  'Basement Finishing'
        },
        {
            imagePath: require('./Transformations/Slide23.JPG'),
            imageName:  'House Sale Declutter and Modernize'
        },
        {
            imagePath: require('./Transformations/Slide26.JPG'),
            imageName:  'Storage Closet to Pantry'
        },
        {
            imagePath: require('./Transformations/Slide29.JPG'),
            imageName:  'Basement Mechanical Closet'
        },
        {
            imagePath: require('./Transformations/Slide30.JPG'),
            imageName:  'Exercise Room'
        },
        {
            imagePath: require('./Transformations/Slide31.JPG'),
            imageName:  'Personal Spa'
        },
        {
            imagePath: require('./Transformations/Slide32.JPG'),
            imageName:  'Personal Lounge and Sauna'
        },
        {
            imagePath: require('./Transformations/Slide33.JPG'),
            imageName:  'Lounge Long View'
        },
        {
            imagePath: require('./Transformations/Slide34.JPG'),
            imageName:  'Custom Laundry Split View'
        },
        {
            imagePath: require('./Transformations/Slide35.JPG'),
            imageName:  'Custom Laundry Full View'
        },
        {
            imagePath: require('./Transformations/Slide37.JPG'),
            imageName:  'Custom Entertainment Cabinet'
        },
        {
            imagePath: require('./Transformations/Slide43.JPG'),
            imageName:  'Modern Basement Stairway'
        },
        {
            imagePath: require('./Transformations/Slide44.JPG'),
            imageName:  'Basement Refresh'
        },
        {
            imagePath: require('./Transformations/Slide45.JPG'),
            imageName:  'Basement Refresh Full View'
        },
        {
            imagePath: require('./Transformations/Slide49.JPG'),
            imageName:  'Full Fireplace Remodel'
        },
        {
            imagePath: require('./Transformations/Slide52.JPG'),
            imageName:  'Reimagened Bedroom and Bath'
        },
        {
            imagePath: require('./Transformations/Slide53.JPG'),
            imageName:  'Walk In Tub Conversion'
        },
    ]
}

export default imagesIndex;